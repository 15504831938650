export function sanitizeDomain(domain: string): string {
  const domainToClean = domain.trim().toLowerCase()

  const matchRegex = domainToClean.match(
    // eslint-disable-next-line no-useless-escape
    /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n?]+)/gim
  )

  if (!matchRegex) return domainToClean

  return matchRegex[0].replace(/^(?:https?:\/\/)?(?:www\.)?/gim, "")
}
